import gql from "graphql-tag";

const GET_ATTRIBUTES = gql`
  query {
    getAttributes {
      id
      slug
      is_required
      position
      cross_cutting
      on_product
      translation {
        id
        attribute_id
        locale
        title
      }
      options {
        id
        attribute_id
        translation {
          id
          option_id
          locale
          title
        }
        media {
          media {
            filename
            path
            id
          }
          is_primary
          position
        }
      }
    }
  }
`;

export {
  GET_ATTRIBUTES
}
