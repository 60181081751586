import gql from "graphql-tag";


const GET_COUNTRIES = gql`
  query {
    getCountries {
      id
      title
      code
    }
}`

export {
  GET_COUNTRIES
}
