<template>
  <div v-if="!loading" class="space-y-6" style="position: relative">
    <General
      id="general"
      class="listingSection"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <ListingDescription
      id="description"
      class="listingSection"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <Gallery id="gallery" class="listingSection" />
    <Amenities
      id="amenities"
      class="listingSection"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <Location
      id="location"
      class="listingSection"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <UnsavedChangesModal
      :isOpen="isModalOpen"
      :isInvalidSectionsPresent="isInvalidSectionsPresent"
      @toggleModal="cancelModal"
      @continue="moveToNextRoute"
      @saveAndContinue="save">
      <template v-slot:title>
        <span class="text-gray-800">
          You have unsaved changes.
        </span>
        <ul class="hidden text-sm text-water font-normal">
          <li v-for="section in unsavedSections" :key="section">
            {{ section.title }}
          </li>
        </ul>
      </template>
      <template v-slot:content><p class="leading-6">You are about to exit the page. <br />All unsaved changes will be lost.</p></template>
      <template v-slot:buttonName>Discard</template>
    </UnsavedChangesModal>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useUnsavedModal } from "@/use/unsavedModal";
import General from "@/components/views/Listing/Details/General";
import ListingDescription from "@/components/views/Listing/Details/Description";
import UnsavedChangesModal from "@/components/UI/Popups/Modal/AppUnsavedChangesModal";
import Gallery from "@/components/views/Listing/Details/Gallery";
import Location from "@/components/views/Listing/Details/Location";
import Amenities from "@/components/views/Listing/Details/Amenities";

export default {
  name: "Details",
  components: { Amenities, Location, Gallery, ListingDescription, General, UnsavedChangesModal },
  setup() {
    const store = useStore();

    const loading = computed(() => store.state.product.isLoadingProduct);
    let sectionObserver = null;

    const route = useRoute();
    const isEditProductMode = !!route.params.id;

    const sectionObserverHandler = (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          // const sectionId = entry.target.id;
          // router.replace({ hash: `#${sectionId}` })
        }
      }
    }

    const observeSections = () => {
      try {
        sectionObserver.disconnect()
      } catch (error) {}

      const options = {
        rootMargin: '0px',
        threshold: 0.7
      }

      sectionObserver = new IntersectionObserver(sectionObserverHandler, options)

      // Observe each section
      const sections = document.querySelectorAll('.listingSection')
      sections.forEach(section => {
        sectionObserver.observe(section)
      })
    }

    onMounted(() => {
      observeSections();
    })

    // unsaved Modal logic
    const {
      checkForUnsavedSections,
      setSectionsValidity,
      saveUnsavedSections,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal
    } = useUnsavedModal(['general', 'description', 'amenities', 'location']);

    const detailsSectionData = ref({
      general: null,
      description: null,
      amenities: null,
      location: null
    });

    const setSectionsData = (section, value) => {
      detailsSectionData.value[section] = value;
    };

    const save = () => {
      const preparedData = {
        translations: [
          {
            small_description: detailsSectionData.value.general.subtitle,
            title: detailsSectionData.value.general.title,
            full_description: detailsSectionData.value.description,
            locale: 'en'
          }
        ],
        phone: detailsSectionData.value.general.phone,
        email: detailsSectionData.value.general.email,
        categories: [
          {
            category_id: detailsSectionData.value.general.selectedCategory.id,
            is_primary: true
          }
        ],
        options: detailsSectionData.value.amenities,
        address: detailsSectionData.value.location.address,
        country_id: detailsSectionData.value.location.country_id,
        location: detailsSectionData.value.location.location
      };

      saveUnsavedSections(preparedData, isEditProductMode);
    };

    return {
      checkForUnsavedSections,
      setSectionsValidity,
      setSectionsData,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal,
      save,
      detailsSectionData,
      loading
    }
  }
};
</script>

<style scoped>

</style>
