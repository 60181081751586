<template>
  <div class="mt-3 desc-section">
    <SectionWrapper
      title="Description"
      description="Give guests a good description of what it's like to stay at your place, and why they should be excited to stay there."
      :body-padding="false"
      :info="errors.fullDescription"
      :isValidSection="isValidSection"
      :loading="isSubmitting"
      class="focus-within:border-highlight-500 focus-within:ring-1 focus-within:ring-highlight-500"
      @saveSection="submit">
      <form class="relative border-t">
        <div class="peer">
          <QuillEditor
            :content="fullDescription"
            :toolbar="[[{ header: [2, 3, false] }], ['bold', 'italic'], [{ list: 'ordered' }, { list: 'bullet' }]]"
            contentType="html"
            theme="snow"
            @update:content="handleInput" />
        </div>
        <div v-if="!isCreateProductMode" class="peer-focus-within:hidden absolute pointer-events-none w-full h-14 bottom-0 bg-gradient-to-t from-white via-white"></div>
        <div v-if="!isCreateProductMode"
             :class="['absolute top-12 right-1.5 cursor-pointer pointer-events-none peer-focus-within:hidden',
                      isCreateProductMode ? '' : 'top-12']">
          <button class="btn btn-xs btn-white">
            <PencilAltIcon class="h-3 w-3 text-gray-600 mr-1 -ml-1" />
            Edit
          </button>
        </div>
      </form>
    </SectionWrapper>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { QuillEditor } from "@vueup/vue-quill";
import * as yup from 'yup';
import { PencilAltIcon } from "@heroicons/vue/solid";
import { computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";

export default {
  name: "ListingDescription",
  components: {
    SectionWrapper,
    QuillEditor,
    PencilAltIcon
  },
  emits: ['checkIsUnsaved', 'checkIsValid', 'updateData'],
  setup(_, { emit }) {
    const store = useStore();
    const initialDescription = computed(() => store.state.product.product.description);

    const route = useRoute();
    const router = useRouter();

    const isCreateProductMode = computed(() => store.state.product.mode === "create");
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const isEmpty = htmlString => {
      const parser = new DOMParser();

      const { textContent } = parser.parseFromString(htmlString, "text/html").documentElement;

      return textContent.trim();
    };

    const schema = yup.object({
      fullDescription: yup.string().required().test({
        test: (value) => isEmpty(value),
        message: "Description is required"
      })
    });

    const { errors, values, handleSubmit, isSubmitting, submitCount } = useForm({
      initialValues: {
        fullDescription: initialDescription.value
      },
      validationSchema: schema
    })

    const { value: fullDescription } = useField('fullDescription')

    const isDescriptionDirty = useIsFormDirty();
    const isDescriptionValid = useIsFormValid();

    const isValidSection = computed(() => isDescriptionDirty.value && isDescriptionValid.value);

    watch(isDescriptionValid, value => emit('checkIsValid', 'description', value));

    const isUnsaved = computed(() => isDescriptionDirty.value && submitCount.value === 0);
    watch(isUnsaved, (newValue) => {
      emit('checkIsUnsaved', {
        title: 'Description'
      }, newValue)
    });

    emit('updateData', 'description', initialDescription.value);

    watch(values, value => {
      emit('updateData', 'description', value.value);
    });

    const isFirstInit = ref(true);

    const handleInput = (content)  => {
      if (!isFirstInit.value) {
        if (content !== initialDescription.value) {
          fullDescription.value = content
        }
      } else {
        isFirstInit.value = false;
      }
    }

    const submit = handleSubmit(async ({ fullDescription }, { resetForm }) => {
      if (isValidSection.value) {
        const reset = () => {
          resetForm({
            touched: false,
            values: {
              fullDescription: initialDescription.value
            }
          })
        }

        if(isCreateProductMode.value) {
          store.commit('product/setData', { field: 'description', step: 'description', data: fullDescription });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          const preparedData = {
            translations: [
              {
                full_description: fullDescription,
                locale: 'en'
              }
            ]
          };

          await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).then(() => {
            reset();
          })
        }
      }
    })

    return {
      fullDescription,
      isValidSection,
      isSubmitting,
      submit,
      errors,
      handleInput,
      isDescriptionDirty,
      isDescriptionValid,
      isCreateProductMode
    }
  }
};
</script>

<style scoped>
.desc-section :deep(.ql-toolbar) {
  @apply border-0 border-b;
}

.desc-section :deep(.ql-snow.ql-toolbar button),
.desc-section :deep(.ql-snow .ql-toolbar button) {
  @apply rounded;
}

.desc-section :deep(.ql-picker-label) {
  @apply rounded border-0;
}

.desc-section :deep(.ql-container) {
  @apply border-0;
}

.desc-section :deep(.ql-editor) {
  @apply md:mx-4 md:my-2 border-0 h-auto text-base prose max-w-none md:px-6 md:pt-5 md:pb-4 h-52 focus:min-h-0 overflow-hidden focus:overflow-auto opacity-60 focus:opacity-100;
}

.desc-section :deep(.ql-editor.ql-blank::before) {
  @apply focus:h-auto overflow-hidden focus:overflow-auto opacity-60 focus:opacity-100;
}

/*
.desc-section >>> .ql-toolbar {
  @apply hidden peer-focus-within:block;
}
*/

</style>
