<template>
  <div>
    <div class="flex justify-between">
      <label :for="refName" class="block text-lg font-medium text-gray-700">{{ title }}</label>
      <button class="block text-xs font-medium text-gray-700 hover:bg-gray-50 px-2 py-1 rounded-md" @click="$emit('focus', $refs[refName])">Edit</button>
    </div>
    <div class="mt-1">
      <input :id="refName" :ref="refName" :value="value" type="text" class="pl-0 border-transparent focus:pl-4 focus:shadow-sm focus:text-gray-700 focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-base rounded-md text-gray-500 transform ease-in-out duration-150" name="title" @input="$emit('update:value', $event.target.value)" />
    </div>
    <span class="input-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "ListingInput",
  props: {
    value: {
      type: String,
      required: true
    },
    refName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    error: {
      type: String
    }
  },
  emits: ['update:value', 'focus']
};
</script>

<style scoped>

</style>
