import gql from 'graphql-tag';

const GET_CATEGORIES_AND_CURRENCIES = gql`
  query {
    getCategories {
      id
      visible
      translations {
        locale
        title
        description
      }
      translation {
        locale
        title
        description
      }
      media {
        media {
          filename
          path
        }
      }
    }
    getCurrencies {
      code
      main
      id
      symbol
    }
  }
`;

export {
  GET_CATEGORIES_AND_CURRENCIES
}
