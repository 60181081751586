<template>
  <SectionWrapper
    title="Location"
    description="Make sure it's correct. It will appear in the booking confirmation and as
    address for guests."
    :is-valid-section="isValidSection"
    :loading="loadingSection"
    @saveSection="submit">
    <div  v-if="!loading" class="flex items-center justify-between mb-2 pt-3 border-t">
      <h2 class="block text-lg font-medium text-gray-700">Address</h2>
      <div class="flex items-center space-x-2">
        <div>
          <button class="block text-xs font-medium text-gray-700 hover:bg-gray-50 px-2 py-1 rounded-md" @click="toggleEditLocation">{{ isLocationEditable ? 'OK' : 'Edit' }}</button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-6 gap-6 pt-3">
      <div v-if="!isLocationEditable" class="col-span-6 lg:col-span-4">
        <div class="bg-gray-100 rounded-md flex items-center">
          <div class="px-3">
            <LocationMarkerIcon class="h-6 w-6 text-gray-500" />
          </div>
          <div class="py-3 pr-3">
            <p class="text-sm text-gray-700">
              {{ address }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="isLocationEditable" class="col-span-6 lg:col-span-4">
        <MapboxInput
          :address="address"
          :address-error="errors"
          @inputHandler="inputHandler"
          @selectAddress="selectAddress"
          @clearAddress="clearAddress"></MapboxInput>
        <p class="text-gray-500 text-xs mt-2">
          The address will be send to the customer in the welcome email. <br /> Make sure it's correct.
        </p>
      </div>

      <div v-if="isLocationEditable" class="col-span-6 w-full !z-10 block relative border border-gray-500 h-[300px] map rounded-xl overflow-hidden border-top">
        <MapboxMap
          :center="location"
          :zoom="8"
          accessToken="pk.eyJ1IjoidmlrdG9yMTIzMyIsImEiOiJja3lvNGQ2aGowaXVnMnduOXhmeXhmbjZoIn0.1DWHPdla7BwxqBbXjZob0w">
          <MapboxMarker :lngLat="location"></MapboxMarker>
        </MapboxMap>
      </div>
      <div v-if="isLocationEditable" class="col-span-6 md:col-span-4">
        <p class="text-gray-600 text-xs">
          If you cannot find your address, please <a href="mailto:host@owayy.com" target="_blank" class="text-highlight-600 underline">contact us</a>
        </p>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import { MapboxMap, MapboxMarker } from "vue-mapbox-ts";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import getCountryId from "@/helpers/getCountryId";
import { LocationMarkerIcon } from "@heroicons/vue/outline";
import { GET_COUNTRIES } from "@/graphql/products/counties/counties";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import MapboxInput from "@/components/UI/MapboxInput/MapboxInput";

export default {
  name: "Location",
  components: {
    SectionWrapper,
    MapboxMap,
    MapboxMarker,
    MapboxInput,
    LocationMarkerIcon
  },
  emits: ['checkIsUnsaved', 'checkIsValid', 'updateData'],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const addressSection = computed(() => store.getters["product/addressData"]);
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');

    const isLocationEditable = ref(isCreateProductMode.value);
    const isAddressSelected = ref(false);

    const location = computed(() => addressSection.value.location);
    const countryId = ref(null);

    const toggleEditLocation = () => {
      isLocationEditable.value = !isLocationEditable.value;
    }

    const { result, loading, onResult } = useQuery(GET_COUNTRIES);

    const countries = computed(() => result.value?.getCountries);

    const schema = yup.object({
      address: yup.string().required().nullable()
    });

    const { errors, values, handleSubmit, submitCount, setFieldValue } = useForm({
      initialValues: {
        address: addressSection.value.address
      },
      validationSchema: schema
    })

    const { value: address } = useField('address');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    watch(isSectionValid, value => emit('checkIsValid', 'location', value));

    const isUnsaved = computed(() => isSectionDirty.value && submitCount.value === 0);

    watch(isUnsaved, value => {
      emit('checkIsUnsaved', {
        title: 'Location'
      }, value)
    });

    onResult(() => {
      emit('updateData', 'location', {
        address:  values.address,
        country_id: countryId.value ?  countryId.value : addressSection.value.countryId,
        location: JSON.stringify({
          lng: location.value[0],
          lat: location.value[1]
        })
      });
    })

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value && isAddressSelected.value);

    const loadingSection = ref(false);

    const submit = handleSubmit( async ({
      address
    }, { resetForm }) => {
      if(isValidSection.value) {
        const preparedData = {
          address,
          country_id: countryId.value ?  countryId.value : addressSection.value.countryId,
          location: {
            lng: location.value[0],
            lat: location.value[1]
          }
        };

        const reset = () => {
          resetForm({
            touched: false,
            values: {
              address: addressSection.value.address
            }
          })
        }

        if(isCreateProductMode.value) {
          store.commit('product/setData', { field: 'addressData', step: 'addressData', data: preparedData });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          loadingSection.value = true;
          preparedData.location=  JSON.stringify({
            lng: location.value[0],
            lat: location.value[1]
          })
          await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).then(() => {
            reset();
          }).finally(() => {
            loadingSection.value = false;
          });
        }
      }
    })

    const inputHandler = () => {
      isAddressSelected.value = false;
    }

    const selectAddress = (value) => {
      isAddressSelected.value = true;
      setFieldValue('address', value.place_name);
      countryId.value = getCountryId(value.context, countries.value);
      store.commit('product/setLocation', value.center);

      emit('updateData', 'location', {
        address:  values.address,
        country_id: countryId.value ?  countryId.value : addressSection.value.countryId,
        location: JSON.stringify({
          lng: location.value[0],
          lat: location.value[1]
        })
      });
    }

    const clearAddress = () => {
      isAddressSelected.value = false;
      address.value = "";
    };

    return {
      toggleEditLocation,
      isLocationEditable,
      isValidSection,
      submit,
      countries,
      loading,
      errors,
      address,
      addressSection,
      location,
      inputHandler,
      selectAddress,
      clearAddress,
      isSectionValid,
      isSectionDirty,
      isAddressSelected,
      loadingSection
    }
  }
};
</script>
