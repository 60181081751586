<template>
  <div class="space-y-4">
    <SectionWrapper
      :title="'General information'"
      :description="`Fill in the general information below. ` +
        `Make sure to capture what makes your place special. <a class='relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500' target='_blank' href='https://owayy.slab.com/public/topics/advice-on-listings-0ow8ss0z'>Get inspiration.</a>`"
      :isValidSection="isValidSection"
      :loading="isSubmitting"
      @saveSection="submit">
      <div>
        <div class="md:w-full">
          <ListingInput title="Title" :value="title" :error="errors.title" refName="title" class="border-t pt-4" @focus="focusInput" @update:value="title = $event" />
          <ListingInput title="Subtitle" :value="subtitle" :error="errors.subtitle" refName="subtitle" class="py-4 my-4 border-b border-t" @focus="focusInput" @update:value="subtitle = $event" />
          <div class="mt-4">
            <!-- Type picker -->
            <div class="mt-4">
              <div class="flex items-center justify-between mb-3">
                <h2 class="block text-lg font-medium text-gray-700">Type</h2>
                <div class="flex items-center space-x-2">
                  <div>
                    <button class="block text-xs font-medium text-gray-700 hover:bg-gray-50 px-2 py-1 rounded-md" @click="toggleEditType">{{ isTypeEditable ? 'OK' : 'Edit' }}</button>
                  </div>
                </div>
              </div>
              <div
                v-if="!isTypeEditable"
                @click="toggleEditType">
                <p
                  class="text-gray-500">
                  {{ selectedCategory.name }}
                </p>
              </div>
              <div v-if="isTypeEditable" class="transform ease-in-out duration-200">
                <RadioGroup v-if="!loading" v-model="selectedCategory">
                  <RadioGroupLabel class="sr-only">
                    Choose a type
                  </RadioGroupLabel>
                  <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                    <RadioGroupOption v-for="category in categories" :key="category.id" as="template" :value="category" :disabled="false" @click="toggleEditType()" v-slot="{ active, checked }">
                      <div :class="[true ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                    active ? 'ring-2 ring-offset-2 ring-highlight-500' : '',
                                    selectedCategory.id === category.id ? 'bg-starryNight text-white hover:bg-opacity-80 ' +
                                      'border-starryNight' :
                                    'bg-white border-gray-300 text-gray-600 hover:bg-gray-50',
                                    'border hover:border-highlight-500 rounded-md flex items-center justify-left ' +
                                      'text-xs flex-shrink-0 overflow-hidden relative'
                      ]">
                        <div class="flex gap-2 text-left items-center">
                          <div
                            class="p-2 bg-gray-100 w-12 h-12 text-center flex flex-col justify-center items-center"
                            :class="selectedCategory.id === category.id || checked ? 'bg-highlight-100' : ''">
                            <img :src="category.image" class="object-cover my-auto" />
                          </div>
                          <div class="text-center">
                            <RadioGroupLabel as="p">
                              {{ category.name }}
                            </RadioGroupLabel>
                          </div>
                        </div>
                        <div v-show="selectedCategory.id === category.id || active" class="absolute inset-0 h-full w-full top-0 p-0.5 text-white">
                          <svg class="w-4 h-4 ml-auto" viewBox="0 0 24 24" fill="none">
                            <circle
                              cx="12"
                              cy="12"
                              r="12"
                              fill="#fff"
                              fill-opacity="0.2" />
                            <path
                              d="M7 13l3 3 7-7"
                              stroke="#fff"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>
              <span>{{ errors.selectedCategory }}</span>
            </div>
          </div>
        </div>

        <div class="mt-4 border-t">
          <!-- Currency -->
          <div class="mt-4">
            <div class="flex items-center justify-between mb-3">
              <h2 class="block text-lg font-medium text-gray-700">Currency</h2>
              <div class="flex items-center space-x-2">
                <div>
                  <button
                    class="block text-xs font-medium text-gray-700 hover:bg-gray-50 px-2 py-1 rounded-md"
                    @click="toggleEditCurrency">
                    {{ isCurrencyEditable ? 'OK' : 'Edit' }}
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="!isCurrencyEditable"
              @click="toggleEditCurrency">
              <p
                class="text-gray-500">
                {{ currency.code }}
              </p>
            </div>
            <div v-else class="transform ease-in-out duration-200">
              <div>
                <RadioGroup v-if="!loading" v-model="currency" class="flex gap-2">
                  <RadioGroupOption
                    v-for="currencyItem in currencies"
                    :key="currencyItem.id"
                    :value="currencyItem">
                    <span :class="[currency.code === currencyItem.code  ? 'border-starryNight bg-starryNight text-white' :
                                     'text-gray-600',
                                   'px-3 py-2 border border-gray-400 rounded-md text-sm cursor-pointer']">
                      {{ currencyItem.code }}
                    </span>
                  </RadioGroupOption>
                </RadioGroup>
              </div>
              <div v-if="!isCreateProductMode" class="bg-yellow-50 border-l-4 border-yellow-500 p-2 mt-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div class="ml-1 flex items-top gap-2 divide-x divide-yellow-300">
                    <div class="pl-2 text-sm text-yellow-700">
                      <p>
                        Changing the currency will <strong>not</strong> automatically update the pricing. <br />
                        Make sure to update every price if you change currency
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="mt-4">
          <div class="flex items-center justify-between mb-2 pt-4 border-t">
            <h2 class="block text-lg font-medium text-gray-700">Contact information</h2>
            <div class="flex items-center space-x-2">
              <div>
                <button class="block text-xs font-medium text-gray-700 hover:bg-gray-50 px-2 py-1 rounded-md" @click="toggleEditContact">{{ isContactEditable ? 'OK' : 'Edit' }}</button>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-6 gap-6 pt-2">
            <div class="col-span-6 sm:col-span-3 lg:col-span-2">
              <label for="email" class="block text-sm font-medium text-gray-700">E-mail</label>
              <input
                id="email"
                v-model.trim="email"
                type="email"
                name="email"
                autocomplete="email"
                class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-md"
                :class="isContactEditable ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                  'focus:ring-highlight-500 focus:border-highlight-500'
                  : 'bg-gray-100 pointer-events-none border-transparent'" />
              <span class="input-error">{{ errors.email }}</span>
            </div>
            <Listbox key="countryCode" v-model="countryCode" class="col-span-6 sm:col-span-3 lg:col-span-2" as="div">
              <label for="countryCode" class="block text-sm font-medium text-gray-700">Country</label>
              <div class='relative mt-1'>
                <ListboxButton
                  :class="
                    ['w-full rounded-md py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500',
                     'focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm',
                     isContactEditable ? 'bg-white border border-gray-300' : 'bg-gray-100 pointer-events-none border-transparent'
                    ]">
                  <span class="block truncate text-left">{{ getCountryName(countryCode) }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
                  <ListboxOptions
                    v-if="countryList.length > 0"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="v in countryList"
                      :key="v"
                      :value="v.id"
                      as="template">
                      <li
                        :class="['group relative cursor-default select-none py-2 pl-3 pr-9',
                                 active ? 'bg-highlight-600 text-white' : 'text-gray-900']"
                        @click="setTouch">
                        <span
                          :class="[
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate',
                          ]">
                          {{ v.name }} {{ v.code ? `(+${v.code})` : '' }}
                        </span>
                        <span
                          v-if="selected"
                          :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                   active ? 'text-white' : 'text-highlight-600']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <div class="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                for="phone"
                :class="isContactEditable ? '' : 'pointer-events-none'"
                class="block text-sm font-medium text-gray-700">
                Phone number
              </label>
              <input
                id="phone"
                v-model.trim="phone"
                type="phone"
                name="phone"
                autocomplete="phone"
                class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-md"
                :class="isContactEditable ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                  'focus:ring-highlight-500 focus:border-highlight-500'
                  : 'bg-gray-100 pointer-events-none border-transparent'" />
              <span class="input-error">{{ errors.phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onErrorCaptured, ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useForm, useField, useIsFormValid, useIsFormDirty } from "vee-validate";
import { ExclamationIcon } from '@heroicons/vue/solid';
import * as yup from 'yup';
import { useRoute, useRouter } from "vue-router";
import ListingInput from "@/components/Listing/ListingInput";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import {
  GET_CATEGORIES_AND_CURRENCIES
} from "@/graphql/products/categories/queries";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "General",
  components: {
    ListingInput,
    SectionWrapper,
    ExclamationIcon
  },
  emits: ['checkIsUnsaved', 'checkIsValid', 'updateData'],
  setup(_, { emit }) {
    const store = useStore();
    const generalInfo = computed(() => store.state.product.product.generalInfo);

    const route = useRoute();
    const router = useRouter();

    const isCreateProductMode = computed(() => store.state.product.mode === "create");

    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const isTypeEditable = ref(false);
    const isCurrencyEditable = ref(false);
    const isContactEditable = ref(isCreateProductMode.value);
    const countryList =ref([{ name:"-",code:null,id:"" },
                            { name:"Albania",code:"355",id:"AL",lang:"en" },
                            { name:"Andorra",code:"376",id:"AD",lang:"en" },
                            { name:"Armenia",code:"374",id:"AM",lang:"en" },
                            { name:"Austria",code:"43",id:"AT",lang:"en" },
                            { name:"Belgium",code:"32",id:"BE",lang:"en" },
                            { name:"Bosnia and Herzegovina",code:"387",id:"BA",lang:"en" },
                            { name:"Bulgaria",code:"359",id:"BG",lang:"en" },
                            { name:"Croatia",code:"385",id:"HR",lang:"en" },
                            { name:"Cyprus",code:"357",id:"CY",lang:"en" },
                            { name:"Czech Republic",code:"420",id:"CZ",lang:"en" },
                            { name:"Denmark",code:"45",id:"DK",lang:"da" },
                            { name:"Estonia",code:"372",id:"EE",lang:"en" },
                            { name:"Faeroe Islands",code:"298",id:"FO",lang:"en" },
                            { name:"Finland",code:"358",id:"FI",lang:"en" },
                            { name:"France",code:"33",id:"FR",lang:"en" },
                            { name:"Georgia",code:"995",id:"GE",lang:"en" },
                            { name:"Germany",code:"49",id:"DE",lang:"en" },
                            { name:"Gibraltar",code:"350",id:"GI",lang:"en" },
                            { name:"Greece",code:"30",id:"GR",lang:"en" },
                            { name:"Greenland",code:"299",id:"GL",lang:"en" },
                            { name:"Hungary",code:"36",id:"HU",lang:"en" },
                            { name:"Iceland",code:"354",id:"IS",lang:"en" },
                            { name:"Ireland",code:"353",id:"IE",lang:"en" },
                            { name:"Italy",code:"39",id:"IT",lang:"en" },
                            { name:"Latvia",code:"371",id:"LV",lang:"en" },
                            { name:"Lithuania",code:"370",id:"LT",lang:"en" },
                            { name:"Luxembourg",code:"352",id:"LU",lang:"en" },
                            { name:"Macedonia",code:"389",id:"MK",lang:"en" } ,
                            { name:"Malta",code:"356",id:"MT",lang:"en" },
                            { name:"Monaco",code:"377",id:"MC",lang:"en" } ,
                            { name:"Montenegro",code:"382",id:"ME",lang:"en" },
                            { name:"Netherlands",code:"31",id:"NL",lang:"en" },
                            { name:"Norway",code:"47",id:"NO",lang:"no" },
                            { name:"Poland",code:"48",id:"PL",lang:"en" },
                            { name:"Portugal",code:"351",id:"PT",lang:"en" },
                            { name:"Romania",code:"40",id:"RO",lang:"en" },
                            { name:"San Marino",code:"378",id:"SM",lang:"en" },
                            { name:"Slovakia",code:"421",id:"SK",lang:"en" },
                            { name:"Slovenia",code:"386",id:"SI",lang:"en" },
                            { name:"Spain",code:"34",id:"ES",lang:"en" },
                            { name:"Sweden",code:"46",id:"SE",lang:"sv" },
                            { name:"Switzerland",code:"41",id:"CH",lang:"en" },
                            { name:"Turkey",code:"90",id:"TR",lang:"en" },
                            { name:"Ukraine",code:"380",id:"UA",lang:"en" },
                            { name:"United Kingdom",code:"44",id:"GB",lang:"en" },
                            { name:"Vatican",code:"379",id:"VA",lang:"en" }]);

    const { result, loading } = useQuery(GET_CATEGORIES_AND_CURRENCIES);
    const currencies = computed(() => result.value?.getCurrencies);
    const categories = computed(() => result.value?.getCategories.map((category) => ({
      id: category.id,
      name: category.translation.title,
      image: category.media != null ? `${process.env.VUE_APP_ASSETS_URL}/${category.media.media.path}` : null
    })));

    const focusInput = (ref) => {
      ref.focus();
    };
    const toggleEditType = () => {
      isTypeEditable.value = !isTypeEditable.value;
    };
    const toggleEditCurrency = () => {
      isCurrencyEditable.value = !isCurrencyEditable.value;
    };
    const toggleEditContact = () => {
      isContactEditable.value = !isContactEditable.value;
    }

    // eslint-disable-next-line vue/max-len
    const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/

    const schema = yup.object({
      email: yup.string().required().email().nullable(),
      phone: yup.string().required().matches(phoneRegExp, 'Phone number is not valid').nullable(),
      title: yup.string().required(),
      subtitle: yup.string().required(),
      selectedCategory: yup.object().required(),
      currency: yup.object().required()
    });

    const { errors, values, handleSubmit, isSubmitting, submitCount } = useForm({
      initialValues: {
        email: generalInfo.value.email,
        phone: generalInfo.value.phone,
        title: generalInfo.value.title,
        subtitle: generalInfo.value.subtitle,
        selectedCategory: generalInfo.value.type,
        currency: generalInfo.value.currency,
        countryCode: generalInfo.value.countryCode ?? ''
      },
      validationSchema: schema
    })

    const { value: email } = useField('email');
    const { value: phone } = useField('phone');
    const { value: title } = useField('title');
    const { value: countryCode } = useField('countryCode');
    const { value: subtitle } = useField('subtitle');
    const { value: selectedCategory } = useField('selectedCategory');
    const { value: currency } = useField('currency');

    const isGeneralDirty = useIsFormDirty();
    const isGeneralValid = useIsFormValid();

    const isValidSection = computed(() => isGeneralDirty.value && isGeneralValid.value);

    watch(isGeneralValid, value => emit('checkIsValid', 'general', value));

    const isUnsaved = computed(() => isGeneralDirty.value && submitCount.value === 0);

    watch(isUnsaved, value => {
      emit('checkIsUnsaved', {
        title: 'General section'
      }, value)
    });

    emit('updateData', 'general', values);

    watch(values, value => {
      emit('updateData', 'general', value);
    });

    onErrorCaptured(err => {
      notify({
        title: err,
        type: 'error'
      });
    }) 

    const getCountryName = function(c) {
      if(!c)
        return `-`;

      const country = countryList.value.filter(x=> x.id === c)[0];

      if(country.code != null)
        return `${country.name} (+${country.code})`;
      return `${country.name}`;
    }

    const submit = handleSubmit( async ({
      email,
      phone,
      title,
      subtitle,
      selectedCategory,
      currency,
      countryCode
    }, { resetForm }) => {

     
      if(isValidSection.value) {
        const reset = () => {
          resetForm({
            touched: false,
            values: {
              email: generalInfo.value.email,
              phone: generalInfo.value.phone,
              countryCode: generalInfo.value.countryCode,
              title: generalInfo.value.title,
              subtitle: generalInfo.value.subtitle,
              selectedCategory: generalInfo.value.type,
              currency:  generalInfo.value.currency
            }
          })
        }
        if(isCreateProductMode.value) {
          const preparedData = {
            title,
            subtitle,
            type: {
              id: selectedCategory.id,
              name: selectedCategory.name
            },
            currency,
            email,
            phone,
            countryCode
          };

          store.commit('product/setData', { field: 'generalInfo', step: 'generalInfo', data: preparedData });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          const preparedData = {
            translations: [
              {
                small_description: subtitle,
                title,
                locale: 'en'
              }
            ],
            phone,
            email,
            country_code: countryCode,
            currency: currency.id,
            categories: [
              {
                category_id: selectedCategory.id,
                is_primary: true
              }
            ]
          };

          await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).then(() => {
            reset();
          });
        }
      }
    });

    return {
      isTypeEditable,
      isCurrencyEditable,
      isContactEditable,
      generalInfo,
      selectedCategory,
      countryCode,
      categories,
      currency,
      focusInput,
      toggleEditType,
      toggleEditCurrency,
      toggleEditContact,
      loading,
      email,
      phone,
      title,
      subtitle,
      errors,
      isValidSection,
      submit,
      isSubmitting,
      isCreateProductMode,
      isUnsaved,
      currencies,
      countryList,
      getCountryName
    }
  }
};
</script>

<style scoped>

</style>
