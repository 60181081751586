<template>
  <SectionWrapper
    title="Gallery upload"
    description="Your photos are the guests first impression of you place, so lets give them a good one.
      (Listings with 10-15 high quality photos often attracts the most traffic.)"
    :show-save-button="isCreateProductMode"
    :is-valid-section="!isSaved"
    :loading="uploadLoading"
    @saveSection="submit">
    <div>
      <GalleryUpload
        :medias="medias"
        :multiple="true"
        :uploadLoading="uploadLoading"
        @updateMedias="updateMedias"
        @submit="submit" />
    </div>
  </SectionWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import GalleryUpload from "@/components/UI/Inputs/Upload/GalleryUpload";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";

export default {
  name: "Gallery",
  components: { SectionWrapper, GalleryUpload },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const medias = computed(() => store.state.product.product.medias);

    const uploadedMedias = ref([]);
    const uploadLoading = ref(false);
    const isSaved = ref(true);

    const submit = async () => {
      uploadLoading.value = true;

      const preparedData = {
        media: uploadedMedias.value
      };

      if(isCreateProductMode.value) {
        const hasPrimaryItem = uploadedMedias.value.some(item => item.is_primary === true);

        // If no item has is_primary true, set it to true for the first item
        if (!hasPrimaryItem && uploadedMedias.value.length > 0) {
          uploadedMedias.value[0].is_primary = true;
        }

        store.commit('product/setData', { field: 'medias', step: 'medias', data: uploadedMedias.value });
        uploadLoading.value = false;
        isSaved.value = true;
        await router.push(nextCreateStep.value);
      } else {
        await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).finally(() => {
          uploadLoading.value = false;
          isSaved.value = true;
        });
      }
    }

    const updateMedias = (value) => {
      uploadedMedias.value = value;
    }

    watch(uploadedMedias, (value) => {
      isSaved.value = false;
      if(value.length > 0 && !isCreateProductMode.value) {
        submit();
      }
    })

    return {
      medias,
      uploadLoading,
      updateMedias,
      submit,
      uploadedMedias,
      isCreateProductMode,
      isSaved
    }
  }
};
</script>

