<template>
  <MapboxGeocoderControl
    accessToken="pk.eyJ1IjoidmlrdG9yMTIzMyIsImEiOiJja3lvNGQ2aGowaXVnMnduOXhmeXhmbjZoIn0.1DWHPdla7BwxqBbXjZob0w"
    countries="dk,se,no,fi,is,de,gb,ie,nl,be,fr,lu,pl,fo"
    language="en"
    types="address"
    @result="resultsHandler">
    <template v-slot:input="{ results }">
      <div :class="['relative transform rounded-xl bg-white ring-1 ring-black ring-opacity-5 transition-all', classes]">
        <div class="relative">
          <SearchIcon class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" aria-hidden="true" />
          <input
            id="street-address"
            ref="streetAddressInput"
            v-model="addressInput"
            type="text"
            name="street-address"
            autocomplete="street-address"
            class="h-12 w-full border-0 bg-transparent pl-11 pr-8 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
            @input="inputHandler" />
          <div v-if="addressInput" class="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 flex items-center">
            <button class="rounded-full bg-gray-400 p-px" @click="clearAddress">
              <XIcon class="h-3 w-3 text-white" />
            </button>
          </div>
        </div>
        <ul
          v-if="showList && results.features"
          class="!z-20 block max-h-72 divide-y divide-gray-100 scroll-py-2 border-t overflow-y-auto py-2 shadow-xl rounded-b-xl text-sm text-gray-800">
          <li
            v-for="address in results.features"
            :key="address.id"
            class="cursor-pointer select-none px-3 py-2 hover:bg-gray-50 flex items-center gap-2"
            @click="selectAddressHandler(address)">
            <div class="w-6">
              <LocationMarkerIcon class="h-5 w-5 text-gray-400" />
            </div>
            <div class="flex-1">
              <span class="block leading-5">{{ address.place_name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </MapboxGeocoderControl>
  <span class="input-error">{{ addressError.address ?? '' }}</span>
</template>

<script>
import { MapboxGeocoderControl } from "vue-mapbox-ts";
import { SearchIcon, XIcon } from "@heroicons/vue/solid";
import { LocationMarkerIcon } from "@heroicons/vue/outline";
import { ref } from "vue";

export default {
  name: "MapboxInput",
  components: {
    LocationMarkerIcon,
    MapboxGeocoderControl,
    SearchIcon,
    XIcon
  },
  props: {
    address: {
      type: [String, Object]
    },
    addressError: {
      type: [String, Object]
    },
    classes: {
      type: String,
      default: 'max-w-xl'
    }
  },
  emits: ['selectAddress', 'inputHandler', 'clearAddress'],
  setup(props, { emit }) {
    const addressInput = ref(props.address);
    const streetAddressInput = ref(null);
    const showList = ref(false);

    const inputHandler = () => {
      showList.value = true;
      emit('inputHandler');
    }

    const clearAddress = () => {
      emit('clearAddress');
      addressInput.value = null;
      showList.value = false;
      streetAddressInput.value.focus();
    };

    const resultsHandler = () => {
      streetAddressInput.value.focus();
    };

    const selectAddressHandler = (value) => {
      emit('selectAddress', value);
      showList.value = false;
      addressInput.value = value.place_name;
    }

    return {
      resultsHandler,
      inputHandler,
      clearAddress,
      selectAddressHandler,
      showList,
      addressInput,
      streetAddressInput
    }
  }
};
</script>

<style scoped>

</style>
