<template>
  <div class="mt-6">
    <div v-if="images.length > 0" class="md:grid grid-cols-2 gap-8">
      <div>
        <div class="shadow-inner-xl p-4 pb-0 rounded-lg bg-gray-50 mb-6 overflow-hidden relative">
          <span class="block text-sm font-medium text-gray-700 mb-1"> Cover photo </span>
          <span class="block text-sm text-gray-500"> This is the first thing that people will see. Make sure it stands out in the crowd. </span>
          <div class="relative p-6">
            <div class="listing-card">
              <div class="bg-white shadow-lg rounded-lg overflow-hidden">
                <div class="aspect-w-16 aspect-h-9">
                  <img :src="coverImage.file || coverImage.media.path" class="object-cover rounded-t-lg" />
                </div>
                <div class="p-5">
                  <div class="h-4 w-2/3 bg-gray-300 rounded"></div>
                  <div class="h-4 mt-1 w-1/3 bg-gray-300 rounded"></div>
                  <div class="h-2 w-5/6 bg-gray-200 hidden sm:block rounded mt-4"></div>
                  <div class="h-2 w-4/5 bg-gray-200 hidden sm:block rounded mt-1"></div>
                  <div class="flex justify-between mt-5">
                    <div class="h-5 w-1/4 bg-gray-200 rounded block"></div>
                    <div class="h-5 w-1/4 bg-gray-200 rounded block"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="absolute inset-0 scale-90 top-6 opacity-30" aria-hidden="true">
              <div class="listing-card top-0 -translate-x-full">
                <div class="bg-white border rounded-lg overflow-hidden">
                  <div class="aspect-w-16 aspect-h-9">
                    <div class="bg-gray-400 object-cover"></div>
                  </div>
                  <div class="p-4">
                    <div class="h-4 w-2/3 bg-gray-300 rounded"></div>
                    <div class="h-4 mt-1 w-1/3 bg-gray-300 rounded"></div>
                    <div class="h-2 w-5/6 bg-gray-200 hidden sm:block rounded mt-4"></div>
                    <div class="h-2 w-4/5 bg-gray-200 hidden sm:block rounded mt-1"></div>
                    <div class="flex justify-between mt-5">
                      <div class="h-5 w-1/4 bg-gray-200 rounded block"></div>
                      <div class="h-5 w-1/4 bg-gray-200 rounded block"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="absolute inset-0 scale-90 top-6 opacity-30" aria-hidden="true">
              <div class="listing-card top-0 translate-x-full">
                <div class="bg-white border rounded-lg overflow-hidden">
                  <div class="aspect-w-16 aspect-h-9">
                    <div class="bg-gray-400 object-cover"></div>
                  </div>
                  <div class="p-4">
                    <div class="h-4 w-2/3 bg-gray-300 rounded"></div>
                    <div class="h-4 mt-1 w-1/3 bg-gray-300 rounded"></div>
                    <div class="h-2 w-5/6 bg-gray-200 hidden sm:block rounded mt-4"></div>
                    <div class="h-2 w-4/5 bg-gray-200 hidden sm:block rounded mt-1"></div>
                    <div class="flex justify-between mt-5">
                      <div class="h-5 w-1/4 bg-gray-200 rounded block"></div>
                      <div class="h-5 w-1/4 bg-gray-200 rounded block"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="mb-1">
          <label class="block text-sm font-medium text-gray-700"> Upload images </label>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <draggable v-if="!isLoadingMedias && !uploadLoading" :list="images" :move="checkMove" item-key="media.id" tag="transition-group" @start="drag = true" @end="endDrag">
            <template #item="{ element, index }">
              <div class="relative group">
                <div class="aspect-w-12 aspect-h-9 rounded-lg bg-gray-100 overflow-hidden cursor-move">
                  <img :src="element.file || element.media.path" class="object-cover rounded-lg" />
                  <div class="absolute inset-0 hidden group-hover:flex flex-col justify-between bg-universeBlue bg-opacity-30 p-1">
                    <div class="flex justify-end">
                      <div class="h-4 flex items-center">
                        <button v-if="element.position !== coverImage.position" class="text-white px-0.5 py-px border rounded hover:bg-gray-100 hover:bg-opacity-30" @click="deleteImage(index, element)">
                          <span class="sr-only">Delete image</span>
                          <XIcon class="h-3 w-3" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div v-if="element.position !== coverImage.position" class="flex justify-center">
                      <button class="text-white text-xs px-1.5 py-0.5 border rounded hover:bg-gray-100 hover:bg-opacity-30" @click="makeCover(element.position)">make cover</button>
                    </div>
                  </div>
                </div>
                <span class="hidden absolute top-0.5 left-0.5 flex items-center justify-center h-4 w-4 rounded-full bg-gray-50 shadow">
                  <span class="text-[10px] mt-px text-starryNight">{{ element.position }}</span>
                </span>
              </div>
            </template>
          </draggable>
          <div v-else class="loading-state col-span-full flex justify-center py-20 bg-gray-800 bg-opacity-5 rounded-lg">
            <LoadingSpinner outer-class="h-10 h-10 text-water" />
          </div>
        </div>
        <label
          id="droparea"
          for="file-upload"
          :class="['mt-4 flex justify-center px-6 pt-5 pb-6 border-dashed border-2 border-gray-300 rounded-md',
                   isDraggedOver ? ' shadow-inner-xl bg-gray-50' : '']"
          @dragenter="isDraggedOver = true"
          @dragleave="handleDragLeave"
          @dropover.prevent
          @dragover.prevent
          @drop.prevent="handleDrop">
          <div
            class="space-y-1 text-center h-full">
            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <div class="flex text-sm text-gray-600">
              <div
                class="relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-highlight-500">
                <span>Upload a file</span>
                <input id="file-upload" :multiple="multiple" name="file-upload" type="file" class="sr-only" @change="handleFileSelect" />
              </div>
              <p class="pl-1">or drag and drop</p>
            </div>
            <p class="text-xs text-gray-500">PNG, JPG, GIF up to 100MB</p>
            <p v-if="uploadErrorData" class="input-error">{{uploadErrorData}}</p>
          </div>
        </label>
      </div>
    </div>
    <!-- empty state -->
    <div
      v-else
      class="relative mt-8 flex justify-center px-6 pt-10 pb-12 border-2 border-gray-300 border-dashed rounded-md"
      @dragenter="isDraggedOver = true"
      @dragleave="handleDragLeave"
      @dropover.prevent
      @dragover.prevent
      @drop.prevent="handleDrop">
      <div class="absolute -top-4 bg-white inline border rounded px-2">
        <span class="text-gray-500 text-xs font-medium uppercase">Empty state</span>
      </div>
      <div class="space-y-1 text-center h-full">
        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <div class="flex text-sm text-gray-600">
          <label
            for="file-upload-second"
            class="relative cursor-pointer bg-white rounded-md font-medium text-highlight-600 hover:text-highlight-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-highlight-500">
            <span>Upload a file</span>
            <input id="file-upload-second" :multiple="multiple" name="file-upload-second" type="file" class="sr-only" @change="handleFileSelect" />
          </label>
          <p class="pl-1">or drag and drop</p>
        </div>
        <p class="text-xs text-gray-500">PNG, JPG, GIF up to 100MB</p>
        <p v-if="uploadErrorData" class="input-error">{{uploadErrorData}}</p>
      </div>
    </div>
    <!-- empty stage end -->
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/solid";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";
import { DELETE_MEDIA } from "@/graphql/products/mutations";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
// import UploadSpinner from "@/components/UI/Indication/UploadSpinner";


const allowedImages = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml"
];

export default {
  name: "GalleryUpload",
  components: {
    // UploadSpinner,
    XIcon,
    draggable,
    LoadingSpinner
  },
  props: {
    medias: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    uploadLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      images: this.medias,
      drag: true,
      isDraggedOver: false,
      uploadErrorData: '',
      sizeLimit: 100,
      makeUpdate: true
    }
  },
  computed: {
    coverImage() {
      if (!this.images) return null;
      return this.images.find(image => image.is_primary) || this.images[0]
    },
    ...mapState({
      isLoadingMedias: state => state.product.isLoadingMedias,
      mode: state => state.product.mode
    })
  },
  watch: {
    images(value) {
      // eslint-disable-next-line no-underscore-dangle
      const preparedImages = value.filter(image => image.__typename !== 'MediaRelation').map(media => ({
        file: media.file,
        is_primary: false,
        position: media.position
      }));
      this.$emit('updateMedias', preparedImages);
    },
    drag(val) {
      if(!val) {
        this.handleDragMove(this.images);
      }
    },
    medias(value) {
      this.images = value;
    }
  },
  methods: {
    endDrag() {
      this.drag = false;
      // eslint-disable-next-line no-underscore-dangle
      const preparedImages = this.images.filter(image => image.__typename !== 'MediaRelation').map(media => ({
        file: media.file,
        is_primary: media.position === 1,
        position: media.position
      }));
      this.$emit('updateMedias', preparedImages);
      this.$emit('submit');
    },
    makeCover(position) {
      if (this.mode === 'create') {
        const medias = this.images.map((image, index) => ({
          ...image,
          position: position === image.position ? 1 : Number(index + 2)
        })).sort((a, b) => a.position - b.position);
        this.$emit('updateMedias', medias);
        this.$emit('submit');
      } else {
        const medias = this.images.map((image, index) => ({
          id: +image.media.id,
          position: position === image.position ? 1 : Number(index + 2)
        }));

        this.$store.dispatch('product/updatePositions', {
          productId: +this.$route.params.id,
          medias
        })
      }
    },
    async deleteImage(imgPosition, image) {
      try {
        this.images.splice(imgPosition, 1);
        await this.$apollo.mutate({
          mutation: DELETE_MEDIA,
          variables: {
            id: +image.media.id,
            userId: null
          }
        });

        const medias = this.medias.map((item, index) => ({
          id: +item.media.id,
          position: Number(index + 1)
        }));

        this.$store.dispatch('product/updatePositions', {
          productId: +this.$route.params.id,
          medias
        })
      } catch (err) {
      }
    },
    handleDragLeave(e) {
      if (e.target.id === "droparea") {
        this.isDraggedOver = false;
      }
    },
    // upload func
    async handleFiles(files, type = "image") {
      const arrFiles = Object.values(files);
      this.uploadErrorData = "";

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arrFiles.length; i++) {
        if (type === "image" && !allowedImages.includes(files[i].type)) {
          this.uploadErrorData = "Images are only allowed";
          return;
        }
      }

      // eslint-disable-next-line no-return-await
      const mapFiles = async (files) => await Promise.all(
        // eslint-disable-next-line no-return-await
        files.map( async (file, index) => await this.mappingDataForUploadFiles(file, index))
          .filter(file => file !== undefined));

      const upload = await mapFiles(arrFiles);
      if (this.uploadErrorData) return;
      this.images = [...this.images, ...upload];
    },
    async mappingDataForUploadFiles(file, index) {
      if (file.size / 1000000 < this.sizeLimit) {
        return {
          media: {
            path: window.URL.createObjectURL(file)
          },
          id: uuidv4(),
          name: file.name,
          file: await this.toBase64(file),
          position: this.images.length + index + 1
        }
      }
      this.uploadErrorData = `File is to large it must be equal or less than ${this.sizeLimit} mb`;
      return {
        error: true
      }

    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async handleFileSelect(e) {
      const files = e.target.files;
      await this.handleFiles(files);
      // this.$refs.imageInput.value = null
    },
    async handleDrop(e) {
      e.preventDefault();
      this.isDraggedOver = false;
      const files = e.dataTransfer.files;
      await this.handleFiles(files);
    },
    async handleDragMove(media) {
      if(!this.makeUpdate) return;
      try {
        const medias = media.map((item, index) => ({
          id: +item.media.id,
          position: Number(index + 1)
        }));

        if(!this.$route.params.id) {
          return;
        }

        this.$store.dispatch('product/updatePositions', {
          productId: +this.$route.params.id,
          medias
        })
      } catch (err) {
      } finally {
        this.drag = false;
      }
    },
    checkMove(evt) {
      this.makeUpdate = evt.draggedContext.element.position !== this.coverImage.position;
      return this.makeUpdate
    }
  }
};
</script>

<style scoped>
.sortable-ghost {
  @apply opacity-30 bg-gray-300 rounded-lg;
}
</style>
