<template>
  <SectionWrapper
    :title="'Amenities'"
    :description="'Tell the guest what is included at your place.'"
    :isValidSection="checkedOptions.length !== 0 && isTouched"
    :info="errorMessage"
    :loading="isUpdating"
    @saveSection="updateAmenities">
    <div v-if="!$apollo.queries.amenities.loading">
      <Disclosure v-for="(cat) in attributes" :key="cat.id" as="div" class="py-2" v-slot="{ open }">
        <DisclosureButton class="relative text-left w-full flex justify-between items-center text-gray-400">
          <div class="h-px absolute top-1/2 -translate-y-1/2 border-t border-gray-400 w-full"></div>
          <div class="z-10 bg-white pr-2 block">
            <span class="block font-medium text-base text-gray-900 capitalize">
              {{ cat.slug }}
              <span class="text-sm text-gray-500 font-normal">
                ({{ checkedAmount(cat) }})
              </span>
            </span>
          </div>
          <div class="z-10 ml-6 h-7 flex items-center bg-white pl-2 text-gray-500">
            <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
          </div>
        </DisclosureButton>
        <DisclosurePanel as="div" class="mt-2 pr-12">
          <div class="pb-8 pt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4">
            <div v-for="option in cat.options" :key="option.id" class="relative flex items-start py-1">
              <div class="flex items-center h-5">
                <input :id="option.id" v-model="option.checked" :aria-describedby="option.translation.title" :name="option.translation.title" type="checkbox" class="focus:ring-highlight-500 h-4 w-4 text-highlight-600 border-gray-300 rounded" @input="touch" />
              </div>
              <div class="ml-3 text-sm">
                <label :for="option.id" class="text-gray-700">{{option.translation.title}}</label>
              </div>
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </SectionWrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import { GET_ATTRIBUTES } from "@/graphql/products/attributes/queries";

export default {
  name: "Amenities",
  components: {
    SectionWrapper
  },
  apollo: {
    amenities: {
      query: GET_ATTRIBUTES,
      update: data => data.getAttributes,
      result(data) {
        const { data: { getAttributes: attributes } } = data;

        this.attributes = attributes.map(attribute => ({
          ...attribute,
          options: attribute.options.map(option => ({
            ...option,
            checked: this.productOptionsId.includes(option.id)
          }))
        }));
      }
    }
  },
  data() {
    return {
      attributes: [],
      isUpdating: false,
      isTouched: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters('product', [
      'productOptionsId',
      'mode',
      'getNextStepCreate'
    ]),
    checkedOptions() {
      return this.attributes.map(attr => attr.options.map(option => option.checked ? option.id : null))
        .flat(2).filter(option => option);
    },
    isCreateProductMode() {
      return this.mode === 'create';
    }
  },
  watch: {
    checkedOptions(value) {
      const isValidAmenities = value.length !== 0;
      this.errorMessage = isValidAmenities ? null : 'You have to select al least one option';
      this.$emit('checkIsValid', 'amenities', isValidAmenities);
      this.$emit('updateData', 'amenities', value);
    },
    isTouched(val) {
      this.$emit('checkIsUnsaved', {
        title: 'Amenities'
      }, val);
    }
  },
  methods: {
    ...mapActions('product', ["updateProduct"]),
    checkedAmount(cat) {
      return cat.options.filter(option => option.checked).length;
    },
    touch() {
      this.isTouched = true;
    },
    async updateAmenities() {
      this.isUpdating = true;
      if (this.isCreateProductMode) {
          this.$store.commit('product/setData', { field: 'options', step: 'options', data: this.checkedOptions });
          await this.$router.push(this.getNextStepCreate)
      } else {
        await this.updateProduct({
          id: +this.$route.params.id,
          data: {
            options: this.checkedOptions
          }
        })
      }
      this.isUpdating = false;
      this.isTouched = false;
    }
  }
};
</script>

<style scoped></style>
